import Swiper, { Navigation } from "swiper";

(function () {
  const testimonialsWidgets = document.querySelectorAll(".js-testimonials");

  testimonialsWidgets.forEach(function (widget) {
    const carousel = widget.querySelector(".js-testimonialsCarousel");
    const controls = widget.querySelector(".js-carouselControls");

    const nextBtn = widget.querySelector(".js-carouselNext");
    const prevBtn = widget.querySelector(".js-carouselPrev");

    const backgrounds = widget.querySelectorAll(".js-testimonialsBackgrounds");
    let activeIndex = 0;

    const swiper = new Swiper(carousel, {
      modules: [Navigation],
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
      speed: 500,
      slidesPerView: 1,
      centeredSlides: true,
      autoHeight: true,
      spaceBetween: 16,
      on: {
        init: (s) => {
          if (s.slides.length <= 1 && controls) {
            controls.style.display = "none";
          }
        },
        slideChange(swiper) {
          if (backgrounds.length) {
            if (backgrounds.length > activeIndex) {
              backgrounds[activeIndex].classList.remove("active");
            }
            activeIndex = swiper.activeIndex;
            if (backgrounds.length > activeIndex) {
              backgrounds[activeIndex].classList.add("active");
            }
          }
        },
      },
      breakpoints: {
        1024: {
          speed: 750,
        },
      },
    });
  });
})();
